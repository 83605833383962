

.iframe iframe{
    width: 100%;
    height: 100%;
}

.remove-img{
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 26px;
    cursor: pointer;

}
.remove-img::after{
    color: #fff;
    background-color: #00000085;
    padding: 0 4px;
    border-radius: 4px;
}
