.invalid>div {
  border-color: #f46a6a;
}
.invalid-feedback{
  font-size: 13px !important;
}
.invalid>div:hover {
  border-color: #f46a6a;
}

.btn-group-example .btn:before {
  display: none !important;
}

.table-responsive {
  min-height: 60vh;
  /* overflow: hidden visible !important; */
}

.table-responsive .table {
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {


  .table-responsive thead {
    display: none;
  }

  .table-responsive tbody,
  .table-responsive td,
  .table-responsive th,
  .table-responsive tr {
    display: block;
    width: 100% !important;
  }

}

@media (max-width: 767px) {
  .responsive-button-label {
    display: none;
  }
}



/*********************/

.sidebar-profile{
  border-bottom: 1px solid #faf9f730;
  padding-bottom: 24px;
}
.sidebar-profile .toggler{
  cursor: pointer;
}
.sidebar-profile .toggler,
.sidebar-profile .collapse-menu{
padding: 0.625rem 1.5rem
}
.sidebar-profile .avatar-img{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
}
.sidebar-profile a{
  font-size: 16px;
}
.page-title h4{
  font-size: 25px !important;
  text-transform: initial !important;
}
.card{
  border-radius: 16px !important;
}
.modal-content{
  border-radius: 16px !important;
}
.custom-rounded{
  border-radius: 16px !important;

}
.card-body{
  padding: 30px !important;
}
.filters-wrapper input,
.filters-wrapper select,
.filters-wrapper .api-select > div:first-of-type{
height: auto !important;
}
input.form-control,
select.form-control,
textarea,
.api-select > div:first-of-type{
  height: 50px;
  border-radius: 8px !important;
}
textarea{
  min-height: 110px !important;
}
.selectbox-wrapper{
  position: relative;
  background-color: white;
}
.selectbox-wrapper::after{
  content: "";
  width: 10px;
  height: 10px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  position: absolute;
  right: 18px;
  top: 46%;
  transform: translateY(-50%) rotate(-45deg);
  z-index: 0;
}
[lang=ar] .selectbox-wrapper::after{
  left: 18px;
  right: initial !important;
}
.selectbox-wrapper select{
  background-color: transparent !important;
  z-index: 1;
  position: relative;
}
.ChooseImage{
  border-radius: 8px !important;
}
label{
  font-size: 18px;
  margin-bottom: 0px !important;
}

.form-group{
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 30px;
  
}
.form-group:last-child{
  margin-bottom: 30px;
}
.form-group:not(:first-child){
  padding-top: 30px;
}

.footer{
  background-color: #304a6f !important;
  color: white !important;
  font-size: 16px !important;
  padding: 20px calc(60px / 2) !important
}

/*@media(min-width: 700px){
  #vertical-menu-btn{
    display: none;
  }
}*/

.page-content{
  padding: 30px 60px 100px 60px !important;
}
.vertical-menu{
  background: #304a6f !important
}
.navbar-brand-box{
  background: #304a6f !important
}
#sidebar-menu ul li a{
  color: white !important;
  font-size: 16px !important;
}
#sidebar-menu ul li a i{
  color: white !important;
}
.rdw-editor-main{
  min-height: 100px;
}


.input-group input{
border-top-left-radius: 0px !important;
border-bottom-left-radius: 0px !important;
}

*{
  text-transform: capitalize !important
}
input{
  text-transform: initial !important

}