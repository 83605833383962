

.custom-table-row{
    flex-wrap: wrap;
  }
  .custom-table-row-wrapper:not(:last-of-type) .custom-table-row{
    margin-bottom: 20px;
    border-bottom: 1px solid #cbcbcb94;
    padding-bottom: 20px;
  }
  .custom-table-header{
    border-bottom: 1px solid #cbcbcb94;
    padding-bottom: 20px;

    margin-bottom: 20px;
  }
  .empty-table{
    border-top: 1px solid #cbcbcb94;
    padding-top: 20px;
    margin-top: 20px;
  }
  .custom-table-row img{
    width: 45px;
    height: 45px;
  }
  .custom-table-row > span{
    font-size: 16px;
  }
  .custom-table-row > span:not(:first-of-type):not(:last-of-type){
    padding: 0px 25px;
  
  }