


.auth-layout{
    background-image: url(../assets/images/auth-bg-light.png);
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.auth-layout img{
    width: 200px;
}
.auth-layout .card{
    background: #ffffff6e
}
.auth-layout .form{
    border-top: 1px solid #eeeeee;
}

.auth-layout h2{
    font-weight: bold;
    color: #7c7c7c;
}

.auth-layout .input-group-text{
    width: 50px !important;
    justify-content: center !important;
    font-size: 21px !important;
    background: white !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important

}

.auth-layout .form-control{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important
}