


.dev-card{
    border: 1px solid #ced4da;
    position: relative;
    cursor: pointer;
}
.dev-card.checked{
    border: 1px solid #304a6f;
 
}
.dev-card img{
    width: 100%;
    height: 90px;
}
.dev-card span{
    border: 1px solid #ced4da;    
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
}
.dev-card.checked span{
    border: 1px solid #304a6f;    
    background-color: #304a6f;
    color: white;
}
