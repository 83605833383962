.loader:nth-child(2){
  left:8px; top:0; height:8px; width:0; animation:slide1 1s linear forwards infinite
}

.loader:nth-child(3){
  right:0; top:8px; width:8px; height:0; animation:slide2 1s linear forwards infinite; animation-delay:0.5s}

.loader:nth-child(4){
  right:8px; bottom:0; height:8px; width:0; animation:slide3 1s linear forwards infinite}

.loader:nth-child(5){
  left:0; bottom:8px; width:8px; height:0; animation:slide4 1s linear forwards infinite; animation-delay:0.5s
}


#logocontainer{
  display:block;
  position:relative;
  /*top:50%;
  left:50%;
      margin-left:calc((70px + 16px) / -2);;
  margin-top:calc((70px + 16px) / -2);;

  */
  width:calc(150px + 16px);
  height:calc(70px + 16px);
  overflow:hidden;
  transition:background-color 500ms;
  cursor:pointer;
}

#pelogo{
  display: block;
  position: absolute;
  left: 8px;
  top: 8px;
  width: 150px;
  height: 70px;

  text-align: center;
  line-height: 70px;
  object-fit: contain;
  padding: 16px;
}

.loader{
  display:block;
  position:absolute;
  background-color: var(--bs-blue);
}

/*Pretty complex animation, so each side needs its own @keyframes.*/

@keyframes slide1{
  50%{width:calc(150px + 8px); margin-left:0;}
  100%{margin-left:calc(150px + 8px);}
}

@keyframes slide2{
  50%{height:calc(70px + 8px); margin-top:0;}
  100%{margin-top:calc(70px + 8px);}
}

@keyframes slide3{
  50%{width:calc(150px + 8px); margin-right:0;}
  100%{margin-right:calc(150px + 8px);}
}

@keyframes slide4{
  50%{height:calc(70px + 8px); margin-bottom:0}
  100%{margin-bottom:calc(70px + 8px);}
}